import React, { useContext, useReducer } from 'react'
import debounce from 'lodash/debounce'
import trim from 'lodash/trim'
import orderBy from 'lodash/orderBy'
import forEach from 'lodash/forEach'
import range from 'lodash/range'
import { graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import filter from 'tcweb-material-components/core/poly/filter'
import find from 'tcweb-material-components/core/poly/find'
import count from 'tcweb-material-components/core/poly/count'
import map from 'tcweb-material-components/core/poly/map'
import Button from 'tcweb-material-components/core/button'
import Section from 'tcweb-material-components/core/section'
import get from 'tcweb-material-components/core/poly/get'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import loadable from '@loadable/component'

import { getLocaleFromWPMLLocale } from '../utilities/locale'
import Searchable from '../components/common/searchable'
import useLocation from '../hooks/useLocation'
import LocaleContext from '../components/context/localeContext'
import LayoutContent from '../components/layout/layoutContent'
import NewsCard from '../components/common/newsCard'

const Tabs = loadable(() => import('tcweb-material-components/core/tabs'))
const Tab = loadable(() => import('tcweb-material-components/core/tab'))
const Grid = loadable(() => import('tcweb-material-components/core/grid'))
const Skeleton = loadable(() => import('@material-ui/lab/Skeleton'))

const SPECIAL_CHAR_REGEXP = /[^\w\s\d]/
const INITIAL_POSTS_TO_SHOW = 5
const POSTS_TO_GET = 6

const useStyles = makeStyles(({ breakpoints, palette, spacing, accessibility: { highContrast } }) => ({
    heading: {
        paddingTop: '30px',
        [breakpoints.up('md')]: {
            paddingTop: '100px'
        }
    },
    section: {
        position: 'relative',
        paddingBottom: '30px',
        [breakpoints.up('md')]: {
            paddingBottom: '100px'
        }
    },
    root: {
        paddingBottom: '3em',
        [breakpoints.up('md')]: {
            paddingTop: '4em',
            paddingBottom: '4em'
        }
    },
    noPostsTitle: { margin: 0 },
    searchTextField: {
        [breakpoints.up('md')]: {
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: 'calc(100vw / 3)'
        },
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontStretch: 'condensed',
        letterSpacing: '.6px'
    },
    searchTextInput: {
        border: `1px solid ${palette.border.primary}`,
        borderRadius: 10,
        backgroundColor: palette.background.default,
        padding: spacing(1),
        '&:hover, &:focus': {
            [highContrast.mediaQuery('active')]: {
                border: `1px solid Highlight`
            }
        }
    },
    searchFocusVisible: {
        [highContrast.mediaQuery('active')]: {
            border: `1px solid Highlight`
        }
    },
    skeleton: { background: 'rgba(0, 0, 0, 0.2)' },
    searchIcon: {
        [highContrast.mediaQuery('active')]: {
            color: 'WindowText'
        }
    }
}))

const getTranslation = (localeContext, post) =>
    localeContext.isImplicit ? post : find((t) => getLocaleFromWPMLLocale(t.locale.locale) === localeContext.locale, post.translated) || post

const getQueryRegExp = (query) =>
    new RegExp(
        String(query)
            .toLowerCase()
            .replace(SPECIAL_CHAR_REGEXP, '')
            .split(' ')
            .filter(Boolean)
            .map((i) => `(?=.*${i})`)
            .join(''),
        'gm'
    )

const getCategories = (posts) => {
    const categories = new Set()

    forEach(posts, (post) => forEach(post.node.categories.nodes, (category) => category.name !== 'Uncategorized' && categories.add(category.name)))

    return [...categories].sort()
}

const onSearch = (localeContext) => (data, params) => {
    let filteredPosts = [...data]

    if (params.category) {
        filteredPosts = filter((item) => {
            const categories = map((category) => category.name.toLowerCase(), Object.values(item.node.categories.nodes))
            return categories.includes(params.category.toLowerCase())
        }, filteredPosts)
    }

    if (trim(params.query)) {
        const query = getQueryRegExp(params.query)

        filteredPosts = filter((item) => {
            const post = getTranslation(localeContext, item.node)
            return [post.title, ...map((tag) => tag.name, item.node.tags.nodes)].join(' ').toLowerCase().replace(SPECIAL_CHAR_REGEXP, '').search(query) > -1
        }, filteredPosts)
    }

    return filteredPosts
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'set':
            return { ...state, ...action.payload }
        default:
            throw new Error(`Invalid action type passed to reducer: ${action.type}`)
    }
}

const NewsPage = (props) => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
    const classes = useStyles()
    const { querystring } = useLocation()
    const { t } = useTranslation()
    const localeContext = useContext(LocaleContext)
    const { sitePosts, siteData } = props.data
    const [state, dispatch] = useReducer(reducer, {
        query: querystring.query || '',
        category: querystring.category || '',
        categories: getCategories(sitePosts.edges),
        posts: orderBy(sitePosts.edges, ['node.sticky', 'node.date'], ['desc', 'desc'])
    })
    const trimmedQuery = trim(state.query)

    // If category is not ALL, show an even number of posts
    const POSTS_TO_SHOW = state.category === '' ? INITIAL_POSTS_TO_SHOW : INITIAL_POSTS_TO_SHOW + 1

    const handleSearchTextChangeWithDebounce = debounce((value) => {
        dispatch({ type: 'set', payload: { query: value } })
    }, 300)

    const handleCategoryChange = (_, category) => {
        dispatch({ type: 'set', payload: { category } })
    }

    return (
        <LayoutContent {...props} setBackgroundImage>
            <Helmet title={`${siteData.siteMetadata.title} | ${t('News')}`} />
            <Typography className={classes.heading} align='center' variant='h1'>
                {t('News')}
            </Typography>
            <Section className={classes.section}>
                <TextField
                    id='search-news'
                    label={t('Search news')}
                    type='search'
                    classes={{ root: classes.searchTextField }}
                    defaultValue={state.query}
                    onChange={(e) => handleSearchTextChangeWithDebounce(e.target.value)}
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position='start'>
                                <FontAwesomeIcon icon='search' className={classes.searchIcon} />
                            </InputAdornment>
                        ),
                        classes: { root: classes.searchTextInput, focused: classes.searchFocusVisible }
                    }}
                />

                {!!count(state.categories) && (
                    <Tabs value={state.category} indicatorType='pill' onChange={handleCategoryChange}>
                        <Tab label='All' value='' aria-label={t('View articles from all categories')} />
                        {map(
                            (category) => (
                                <Tab
                                    key={`category-${category}`}
                                    label={t(category)}
                                    value={category}
                                    aria-label={t('slug:filter-articles-against-category-category', { category })}
                                />
                            ),
                            state.categories
                        )}
                    </Tabs>
                )}

                <Searchable
                    enablePagination
                    page={querystring.page}
                    onSearch={onSearch(localeContext)}
                    perPage={POSTS_TO_GET}
                    initialPerPage={POSTS_TO_SHOW}
                    params={{
                        query: state.query,
                        category: state.category
                    }}
                    data={state.posts}
                    emptyComponent={
                        <Typography variant='h1' classes={{ root: classes.noPostsTitle }} aria-live='polite'>
                            {t('No News, check back soon')}
                        </Typography>
                    }
                    loadingComponent={
                        <Grid cols={{ xs: 1, md: 2 }}>
                            {range(POSTS_TO_SHOW).map((i) => (
                                <div key={`loading-${i}`}>
                                    <Skeleton variant='rect' height={isDesktop ? '400px' : '200px'} className={classes.skeleton} />
                                    <Skeleton width='10%' className={classes.skeleton} />
                                    <Skeleton width='50%' className={classes.skeleton} />
                                    <Skeleton height='50px' className={classes.skeleton} />
                                    <Skeleton width='10%' className={classes.skeleton} />
                                </div>
                            ))}
                        </Grid>
                    }
                    nextPageComponent={
                        <Button classes={{ root: classes.showMoreButton }} variant='contained' color='primary' title={t('Show More')}>
                            {t('Show More')}
                        </Button>
                    }
                >
                    {({ data, page }) => {
                        const expectedNumPosts = POSTS_TO_GET * (page - 1) + POSTS_TO_SHOW
                        const focusedPost = count(data) === expectedNumPosts ? count(data) - POSTS_TO_GET : expectedNumPosts - POSTS_TO_GET

                        return (
                            <Grid cols={{ xs: 1, md: 2 }} featureFirst={!trimmedQuery && !state.category}>
                                {map((edge, i) => {
                                    const post = edge.node
                                    const locPost = getTranslation(localeContext, edge.node)

                                    return (
                                        <NewsCard
                                            key={`card-${post.slug}`}
                                            title={locPost.title}
                                            date={post.date}
                                            formattedDate={post.formattedDate}
                                            slug={post.slug}
                                            excerpt={locPost.excerpt}
                                            featuredMedia={get(post, 'featuredImage.node')}
                                            headerVariant='h2'
                                            isFeaturedCard={i === 0 && !trimmedQuery && !state.category}
                                            linkRefCallback={(node) => {
                                                if (node && i === focusedPost && page !== 1) {
                                                    setTimeout(() => node.focus(), 500)
                                                }
                                            }}
                                        />
                                    )
                                }, data)}
                            </Grid>
                        )
                    }}
                </Searchable>
            </Section>
        </LayoutContent>
    )
}

export const pageQuery = graphql`
    query NewsQuery {
        siteData: site {
            siteMetadata {
                title
            }
        }
        sitePosts: allWpSitePost (filter: {locale: {locale: {eq: "en_US"}}}){
            totalCount
            edges {
                node {
                    title
                    excerpt
                    slug
                    link
                    date
                    isSticky
                    categories {
                       nodes {
                            id
                            name
                        }
                    }
                    tags {
                        nodes {
                            id
                            name
                        }
                    }
                    formattedDate: date(formatString: "MMM D, YYYY")
                    featuredImage{
                        node {
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                                }
                            }
                        }
                    }
                    translated {
                        title
                        excerpt
                        slug
                        link
                        date
                        formattedDate: date(formatString: "MMM D, YYYY")
                        locale {
                            locale
                        }
                    }
                }
            }
        }
    }
`

export default NewsPage
