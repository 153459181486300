import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import get from 'tcweb-material-components/core/poly/get'
import { useTheme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Card from 'tcweb-material-components/core/card'
import CardMedia from 'tcweb-material-components/core/cardMedia'
import CardContent from 'tcweb-material-components/core/cardContent'
import count from 'tcweb-material-components/core/poly/count'
import { truncateOnNonWebkit } from 'tcweb-material-components/core/utilities/helpers'

import PlaceholderImg from './placeholderImg'
import { decodeText } from '../../utilities/helpers'
import Link from '../common/link'

const TITLE_LENGTH = 60
const EXCERPT_LENGTH = 150

export default ({ className, title, formattedDate, slug, excerpt, featuredMedia, isFeaturedCard, linkRefCallback, headerVariant='h3' }) => {
    const { palette } = useTheme()
    const { t } = useTranslation()

    title = truncateOnNonWebkit(decodeText(title), TITLE_LENGTH)
    excerpt = truncateOnNonWebkit(decodeText(excerpt), EXCERPT_LENGTH)

    return (
        <Card className={className} featured={isFeaturedCard}>
            <CardMedia>
                {get(featuredMedia, 'localFile.childImageSharp') ? (
                    <GatsbyImage
                        image={featuredMedia.localFile.childImageSharp.gatsbyImageData}
                        backgroundColor={palette.img.backgroundLoading}
                        alt={featuredMedia.alt_text}
                        tag={null} />
                ) : (
                    <PlaceholderImg />
                )}
            </CardMedia>
            <CardContent gutters='none'>
                <Typography component='time' className='h4'>{formattedDate}</Typography>
                <Typography variant={headerVariant} className='h3'>{title}</Typography>
                {!!count(excerpt) && <Typography className='line-clamp-3-xs'>{excerpt}</Typography>}
                <Link
                    color='primary'
                    className='h4'
                    aria-label={title ? t('slug:read-more-about-articletitle', { articleTitle: title }) : t('Read More')}
                    title={title ? t('slug:read-more-about-articletitle', { articleTitle: title }) : t('Read More')}
                    to={`${process.env.GATSBY_SITE_URL}/${slug}`}
                    ref={linkRefCallback}
                >
                    {t('Read More')}
                    <FontAwesomeIcon
                        icon={['tcs', 'arrow-right']}
                        size='1x'
                        color={palette.text.primary}
                        fixedWidth
                    />
                </Link>
            </CardContent>
        </Card>
    );
}
